import Col from 'react-bootstrap/Col'
import LandingWrapper from '../../components/landing/elements/LandingWrapper'
import Card from '../../components/landing/elements/Card'
import Text from '../../components/landing/elements/Text'
import CtaButton from '../../components/landing/elements/CtaButton'
import Title from '../../components/landing/elements/Title'
import Block from '../../components/landing/elements/Block'
import { Button } from 'react-bootstrap'
import Image from '../../components/landing/elements/Image'
import Link from 'next/link'
import useIsMobile from '../../hooks/useIsMobile'

export default function IndustryLanding () {
  return (
    <LandingWrapper>
      <BlockIntro />

      <BlockThreeBenefits />

      <BlockConf1 />

      <BlockBeAPart />

      <Block>
        <Col
          xs={12}
          className={'overflow-hidden'}
        >
          <BlockVideo />
        </Col>
      </Block>

      <BlockUltimateResourceKit />

      <BlockConf2 />

      <BlockStruggle />

      <Block
        background={'bg-primary'}
        color={'text-white'}
        backgroundImage={'/img/landing/next/ivy-industry/takeoff.jpg'}
        leftGradient
      >
        <Col xs={12} md={9}>
          <Card
            gap={5}
            className={'p-4 p-md-5'}
          >
            <div>
              <Title size={2}>
                This Offer Won’t Wait:
              </Title>
              <Title size={1}>
                <span className={'text-success'}>Secure Your Future at 50% Off — For a Limited Time Only</span>
              </Title>
            </div>
            <Text size={5} accent>
              A $299/m investment for a partnership valued at $599/m is an unheard-of opportunity.
            </Text>
            <Text size={5} accent>
              By joining at the Couch Conference, you’re locking in a rate designed to make this decision easy—but this
              is a one-time offer that won’t last long.
            </Text>
            <Text size={5} accent>
              This isn’t just a discount; it’s your chance to become part of something transformative at half the price.
            </Text>
            <div className={'d-flex'}>
              <CtaButton
                label={'Apply Now'}
                subtitle="Be a part of the Founding Group"
                href={'/ivy-industry-application'}
              />
            </div>
          </Card>
        </Col>
      </Block>

      <BlockSummary />
    </LandingWrapper>
  )
}

function BlockIntro () {
  return (
    <Block
      background={'bg-primary'}
      color={'text-white'}
      fullWidth
      backgroundImage={'/img/landing/next/ivy-industry/bg.jpg'}
      leftGradient
    >
      <Col xs={12} md={8}>
        <div className={'py-3 py-md-5 my-md-5'}>
          <div className={'d-md-none mt-4'}>
            &nbsp;
          </div>
          <Card gap={5} className={'mt-5 mt-md-4'}>
            <div>
              <Title
                size={1}
                h
                display
              >
                Ivy Industry
              </Title>
              <Text size={4} className={'fst-italic text-success'} accent>
                Building the Next Generation
                <br/>
                of 7-figure Couch Flipping Businesses
              </Text>
            </div>

            <div className={'d-flex flex-column gap-4'}>
              <Text size={4} accent>
                Tired of flipping alone? Want to join a winning team?
              </Text>
              <Text size={5} accent>
                For those who want to build the next generation of muti-million dollar furniture businesses — this partnership is for you.
              </Text>
            </div>

            <div className="d-flex">
              <CtaButton
                label={'Apply Now'}
                subtitle="Be a part of the Founding Group"
                href={'/ivy-industry-application'}
              />
            </div>
          </Card>
        </div>
      </Col>
    </Block>
  )
}

function BlockSummary () {
  return (
    <Block>
      <Col xs={12}>
        <Title size={2}>
          Here’s what you gain if you <span className={'text-success'}>act now</span>:
        </Title>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <Card>
          <CircleNumber number={1} />
          <Text size={5} accent>
            <span className={'fw-semibold'}>Immediate access</span> to every tool, resource, and advantage Ivy Flip offers.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <Card>
          <CircleNumber number={2} />
          <Text size={5} accent>
            <span className={'fw-semibold'}>Exclusive access</span> to retailer liquidations – a scalable source of inventory on your journey to 7 figures.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <Card>
          <CircleNumber number={3} />
          <Text size={5} accent>
            <span className={'fw-semibold'}>Exclusive insights</span>, mentorship, and proven strategies to fast-track your business growth.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={3}>
        <Card>
          <CircleNumber number={4} />
          <Text size={5} accent>
            <span className={'fw-semibold'}>Unmatched networking</span> and support from other elite flippers who are scaling their businesses alongside you.
          </Text>
        </Card>
      </Col>

      <Col xs={12} md={{ span: 4, offset: 2 }}>
        <Card>
          <Text size={5} className={'text-warning'} accent>
            <span className={'fw-semibold'}>But if you miss this window</span>, you’ll be paying full price — or worse, watching from the sidelines as your
            competitors seize the advantage you could have had.
          </Text>
        </Card>
      </Col>

      <Col xs={12} md={4}>
        <Card>
          <Text size={5}  className={'text-warning'} accent>
            <span className={'fw-semibold'}>Don’t let this opportunity slip away</span>.
            <br/>
            Join the Ivy Industry Partnership now, lock in your discounted
            rate, and watch your business transform.
          </Text>
        </Card>
      </Col>

      <Col xs={12}>
        <Card>
          <Title size={3} accent center>
            The choice is yours — but the window is closing fast.
          </Title>
        </Card>
      </Col>

      <Col xs={12}>
        <div className={'d-flex justify-content-center'}>
          <div className={'d-inline-block'}>
            <CtaButton
              label={'Apply Now'}
              subtitle="Be a part of the Founding Group"
              href={'/ivy-industry-application'}
            />
          </div>
        </div>
      </Col>
    </Block>
  )
}

function BlockConf1 () {
  return (
    <Block
      background={'bg-success bg-opacity-10 border border-5 border-success'}
    >
      <Col xs={12} md={8}>
        <Card className={'p-4 p-md-5'}>
          <Title size={2}>
            Exclusive Couch Conference Offer
          </Title>
          <Text size={4} accent>
            For a limited time only, Couch Conference attendees can join the Ivy Industry Partnership for
            just <span className={'fw-semibold'}><span className={'text-success'}>$299/m</span> instead of the regular $599/m rate!</span>
          </Text>
          <Text size={5}>
            This offer unlocks a comprehensive suite of tools and exclusive perks designed to propel your business
            growth faster than ever.
          </Text>
          <div className="d-flex">
            <CtaButton
              label={'Lock in Your Rate Now'}
              subtitle="Be a part of the Founding Group"
              href={'/ivy-industry-application'}
            />
          </div>
        </Card>
      </Col>
    </Block>
  )
}

function BlockConf2 () {
  return (
    <Block
      background={'bg-warning bg-opacity-10 border border-5 border-warning'}
    >
      <Col xs={12} md={8}>
        <Card className={'p-4 p-md-5'}>
          <Title size={2}>
            <strike className={'text-muted me-3'}>$599/m</strike>
            $299/m - Save 50% for Life!
          </Title>
          <Text size={4} accent>
            By joining at the Couch Conference, you’re locking in a rate designed to make this decision easy—but this
            is a one-time offer that won’t last long.
          </Text>
          <Text size={4} accent>
            You'll get everything included for <span className={'text-warning fw-semibold'}>just $299 a month</span>.
          </Text>
          <div className="d-flex">
            <CtaButton
              label={'Lock in Your Rate Now'}
              subtitle="Be a part of the Founding Group"
              variant={'warning'}
              href={'/ivy-industry-application'}
            />
          </div>
        </Card>
      </Col>
    </Block>
  )
}

function BlockUltimateResourceKit () {
  return (
    <Block>
      <Col xs={12}>
        <Title size={2}>
          The Ultimate Resource Kit:
          <br/>
          <span className={'text-success'}>A Library of Ivy Flip’s Proven Tools</span>
        </Title>
      </Col>
      <Col xs={12}>
        <Card>
          <Text size={5} accent>
            By joining the Ivy Industry Partnership, you’re not just gaining a community —
            <br/>
            you’re gaining access to a
            suite of essential tools that drive efficiency, sales, and insight.
          </Text>
          <Text>
            This partnership grants you:
          </Text>
        </Card>
      </Col>

      <Col xs={12} md={5} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/product-notifications.png'}
          />

          <Title size={4}>
            Instant Marketplace Notifications
          </Title>

          <Text>
            Be first in line for the hottest inventory, every time.
          </Text>

          <Link href={'/product'}>
            <Button variant={'light'}>
              Learn More
            </Button>
          </Link>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/product-crosslisting.png'}
          />

          <Title size={4}>
            Listing Made Easy
          </Title>

          <Text>
            Sync and sell across platforms in seconds, not hours.
          </Text>

          <Link href={'/product-listing'}>
            <Button variant={'light'}>
              Learn More
            </Button>
          </Link>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/product-sales-tracker.png'}
          />

          <Title size={4}>
            Sales Tracker
          </Title>

          <Text>
            Keep tabs on your performance and optimize for more sales.
          </Text>

          <Link href={'/next/sales-tracker'}>
            <Button variant={'light'}>
              Learn More
            </Button>
          </Link>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/product-pickup.png'}
          />

          <Title size={4}>
            Pickup Feed
          </Title>

          <Text>
            Free couch removal and consignment leads at your finger tips. Get off-market deals you won't find on
            Facebook marketplace.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/product-ivy-store.png'}
          />

          <Title size={4}>
            Ivy Store
          </Title>

          <Text>
            Your own storefront to showcase inventory with style.
          </Text>

          <Link href={'/next/ivy-store'}>
            <Button variant={'light'}>
              Learn More
            </Button>
          </Link>
        </Card>
      </Col>

      <Col xs={12} md={6} lg={4}>
        <Card className={'bg-light p-5 br-3'}>
          <Text size={5} accent>
            Each of these tools alone is powerful, but combined,
            they’re <span className={'fw-bold'}>the backbone of the Ivy Industry Partnership</span> and
            your growth.
          </Text>
          <CtaButton
            label={'Apply Now'}
            subtitle="Be a part of the Founding Group"
            href={'/ivy-industry-application'}
          />
        </Card>
      </Col>
    </Block>
  )
}

function BlockThreeBenefits () {
  return (
    <Block alignVertical>
      <Col xs={12}>
        <Card className={'mb-3'}>
          <Title size={2} center>
            Three <span className={'text-success'}>Exclusive Benefits</span> for Ivy Industry Partners
          </Title>
        </Card>
      </Col>

      <Col xs={{ span: 12, order: 0 }} md={{ span: 6 }}>
        <Image
          height={450}
          src={'/img/landing/next/ivy-industry/one-on-one.png'}
          className={'bg-light'}
          contain
        />
      </Col>
      <Col xs={{ span: 12, order: 1 }} md={{ span: 6 }}>
        <Card>
          <CircleNumber number={1} />
          <Title size={3}>
            Weekly Mastermind & 1-on-1 Coaching – <span className={'text-success'}>The Personal Growth Formula</span>
          </Title>
          <Text>
            Our masterminds bring together Industry partners to troubleshoot challenges in real-time.
          </Text>
          <Text>
            We meet every week, diving into what’s holding you back and sharing actionable strategies to keep you
            moving forward.
          </Text>
          <Text>
            And when you need to drill down into the nitty-gritty, there are 1-on-1 coaching sessions available each
            week — helping you stay on track to achieve that next big leap.
          </Text>

          {/*<div className={'d-inline-block'}>*/}
          {/*  <Button variant={'light'}>*/}
          {/*    Learn More*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </Card>
      </Col>

      <Col xs={{ span: 12, order: 3 }} md={{ span: 6 }}>
        <Card>
          <CircleNumber number={2} />
          <Title size={3}>
            Exclusive Access to Retailer Liquidations –
            <br/>
            <span className={'text-success'}>A Game-Changer for Inventory</span>
          </Title>
          <Text>
            Ivy Industry Partners get exclusive access to retailer liquidations—meaning you get a steady flow of
            high-quality inventory from top brands at a fraction of the market rate.
          </Text>
          <Text>
            Others spend hours searching; Ivy Industry Partners spend hours selling.
          </Text>
          <Text>
            Best of all, you'll be known as the reseller in your market with discount offers on our premium brands.
          </Text>
          {/*<div className={'d-inline-block'}>*/}
          {/*  <Button variant={'light'}>*/}
          {/*    Learn More*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </Card>
      </Col>
      <Col xs={{ span: 12, order: 2 }} md={{ span: 6 }}>
        <Card>
          <Image
            height={450}
            src={'/img/landing/next/ivy-industry/retailer-liquidations.png'}
            className={'bg-light'}
            contain
          />
        </Card>
      </Col>

      <Col xs={{ span: 12, order: 4 }} md={{ span: 6 }}>
        <Image
          height={450}
          src={'/img/landing/next/ivy-industry/google-ads.png'}
          className={'bg-light'}
          contain
        />
      </Col>
      <Col xs={{ span: 12, order: 5 }} md={{ span: 6 }}>
        <Card>
          <CircleNumber number={3} />
          <Title size={3}>
            Managed Ad Service – Harness <span className={'text-success'}>Google Ads for Your Business</span> Without Lifting a Finger
          </Title>
          <Text>
            Imagine having Google Ads driving steady couch pickups for free and selling out your inventory faster.
          </Text>
          <Text>
            Sounds expensive and technical, right? Not for Ivy Industry Partners.
          </Text>
          <Text>
            We know how to target, optimize, and manage ads to fill
            up your inventory pipeline and keep those sales moving.
            You focus on growth, and we’ll drive the traffic.
          </Text>
          {/*<div className={'d-inline-block'}>*/}
          {/*  <Button variant={'light'}>*/}
          {/*    Learn More*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </Card>
      </Col>
    </Block>
  )
}

function BlockStruggle () {
  return (
    <Block>
      <Col xs={12}>
        <Card>
          <Title size={3}>
            Why <span className={'text-success'}>Ivy Industry Partners Succeed</span> Where <span className={'text-muted'}>Others Struggle</span>
          </Title>
          <Text size={5} accent>
            The couch flipping market is becoming increasingly competitive, and flippers need every advantage to
            thrive.
            <br/>
            Ivy Industry Partners, however, aren’t left scrambling.
          </Text>
          <Text size={5}>
            They’re equipped with:
          </Text>
        </Card>
      </Col>

      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/f-scalable.png'}
          />
          <Title size={4}>
            Scalable Inventory
          </Title>
          <Text>
            We help you get the right inventory you need to grow your business, so you don't have waste time hunting for deals.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/f-full-access.png'}
          />
          <Title size={4}>
            Full Platform Access
          </Title>
          <Text>
            Sales tracking, marketplace notifications, listing management, an operations platform and more.
            <br/>
            Streamline your operations with all of the Ivy Flip software tools.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <Image
            src={'/img/landing/next/ivy-industry/f-strategy.png'}
          />
          <Title size={4}>
            Strategy & Support
          </Title>
          <Text>
            Weekly masterminds and 1-on-1 coaching to overcome challenges and seize opportunities at every turn.
            <br/>
            Plus managed advertising services to take your business to the next level
          </Text>
        </Card>
      </Col>
      <Col xs={12}>
        <div className={'d-flex justify-content-center'}>
          <div className={'d-inline-block'}>
            <CtaButton
              label={'Apply Now'}
              subtitle="Be a part of the Founding Group"
              href={'/ivy-industry-application'}
            />
          </div>
        </div>
      </Col>
    </Block>
  )
}

function BlockBeAPart () {
  return (
    <Block>
      <Col xs={12}>
        <Card>
          <Title size={2}>
            Be a Part of Something <span className={'text-success'}>Bigger</span> Than Yourself
          </Title>
          <Text size={4} accent>
            Ivy Industry is an elite network of flippers who support each other by pooling data, resources, and strategies to get ahead of the pack.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Image
            height={300}
            src={'/img/landing/next/ivy-industry/mastermind.png'}
          />
          <Title size={4}>
            Weekly Problem-Solving Sessions
          </Title>
          <Text size={5}>
            Every week, you’ll join your mastermind group to tackle 2-3 of the most pressing challenges facing your business in a moderated discussion.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Image
            height={300}
            src={'/img/landing/next/ivy-industry/1-on-1.png'}
          />
          <Title size={4}>
            1-on-1 Coaching
          </Title>
          <Text size={5}>
            Personalized guidance to navigate the complex path to scaling.
            <br/>
            Ivy Industry Partners are already seeing results — don’t miss the chance to join them.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Image
            height={300}
            src={'/img/landing/next/ivy-industry/local-exclusivity.png'}
          />
          <Title size={4}>
            Local Exclusivity
          </Title>
          <Text size={5}>
            Share without fear of competition.
          </Text>
          <Text size={5}>
            You’ll be the only flipper from your city in your mastermind group.
          </Text>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Image
            height={300}
            src={'/img/landing/next/ivy-industry/resource-polling.png'}
          />
          <Title size={4}>
            Resource & Data Pooling
          </Title>
          <Text size={5}>
            Benchmark your results and get access to proven sales strategies — if a campaign works in one city, you’ll have the playbook to replicate its success in yours.
          </Text>
        </Card>
      </Col>
    </Block>
  )
}

function BlockVideo () {
  const isMobile = useIsMobile()

  return (
    <div className={'d-flex justify-content-center'}>
      <iframe
        width={isMobile ? '560' : '840'}
        height={isMobile ? '315' : '472'}
        src="https://www.youtube.com/embed/bHqkU2ImL9k"
        title="Ivy Industry"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{ maxWidth: '100%' }}
      />
    </div>
  )
}

const size = 48

function CircleNumber ({ number }) {
  return (
    <div
      className={'bg-success text-white fs-3 fw-bold d-flex justify-content-center align-items-center mt-3'}
      style={{
        width: size,
        height: size,
        borderRadius: '50%'
      }}
    >
      {number}
    </div>
  )
}